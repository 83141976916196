export class ClassResults {
  constructor(opts = undefined) {
    if (opts !== null) {
      this._id = opts._id && opts._id;
      this.classId = opts.classId && opts.classId;
      this.dogId = opts.dogId && opts.dogId;
      this.dogTime = opts.dogTime && opts.dogTime;
      this.dogJumpHeight = opts.dogJumpHeight && opts.dogJumpHeight;
      this.runComment = opts.runComment && opts.runComment;
      this.courseTime = opts.courseTime && opts.courseTime;
      this.yards = opts.yards && opts.yards;
      this.qualified = opts.qualified && opts.qualified;
      this.doubleQualified = opts.doubleQualified && opts.doubleQualified;
      this.placement = opts.placement ? opts.placement : 0;
      this.runScoreboard = opts.runScoreboard && opts.runScoreboard;
      this.totalDogsInClass = opts.totalDogsInClass && opts.totalDogsInClass;
      this.totalDogsQualified =
        opts.totalDogsQualified && opts.totalDogsQualified;
      this.titlePoints = opts.titlePoints && opts.titlePoints;
      this.titleLeg = opts.titleLeg && opts.titleLeg;
      this.titleTowards = opts.titleTowards && opts.titleTowards;
      this.titleAchieved = opts.titleAchieved && opts.titleAchieved;
      this.dogScore = opts.dogScore && opts.dogScore;
      this.didNotFinish = opts.didNotFinish && opts.didNotFinish;
      this.fixAndContinue = opts.fixAndContinue && opts.fixAndContinue;
    }
  }
}
