<template>
  <div class="scoreboard-row" :class="{ isDogPlacement: isDogPlacement }">
    <p class="place">{{ place }}</p>
    <p class="dog-score">{{ dogScore.dogScore }}</p>
    <p class="dog-time">{{ dogScore.dogTime }}</p>
    <p class="dog-YPS">{{ calcYPS }}</p>
    <p class="dog-name">{{ dogScore.dogName }}</p>
    <p class="dog-breed">{{ dogScore.dogBreed }}</p>
  </div>
</template>

<script>
import { ScoreboardRow } from "@/models/scoreboardRowModel";

export default {
  props: {
    place: Number,
    score: Object,
    isDogPlacement: Boolean,
    courseLength: Number,
  },
  data() {
    return {
      dogScore: new ScoreboardRow(this.score),
    };
  },
  computed: {
    calcYPS() {
      return this.dogScore.dogTime
        ? this.courseLength &&
            (this.courseLength / this.dogScore.dogTime).toFixed(2)
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
// Global scoreboard styles used for this component
</style>
