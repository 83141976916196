<template>
  <div class="dog-scores">
    <el-row
      :gutter="10"
      type="flex"
      justify="space-evenly"
      class="secondary-text-color"
    >
      <el-col :span="8" v-if="classResults.didNotFinish">
        Did not finish (DNF)
      </el-col>
      <el-col :span="8" v-if="classResults.fixAndContinue">
        Chose to fix and continue
      </el-col>
    </el-row>

    <el-row :gutter="10" type="flex" justify="center">
      <el-col :span="4"
        >Score<br /><span>{{ classResults.dogScore }}</span></el-col
      >
      <el-col :span="4"
        >Dog's Time<br /><span>{{ classResults.dogTime }}</span></el-col
      >
      <el-col :span="4"
        >Jump Height<br /><span>{{ classResults.dogJumpHeight }}</span></el-col
      >
      <el-col :span="4"
        >Course Time<br /><span>{{ classResults.courseTime }}</span></el-col
      >
      <el-col :span="4"
        >Yards<br /><span>{{ classResults.yards }}</span></el-col
      >
      <el-col :span="4"
        >YPS<br /><span>{{ calcYPS }}</span></el-col
      >
    </el-row>
    <el-row :gutter="10" type="flex" justify="center">
      <el-col :span="4"
        >Placement<br /><span>{{
          classResults.placement > 0 ? classResults.placement : ""
        }}</span></el-col
      >
      <el-col :span="4"
        >Qualified<br /><span>{{ classResults.qualified }}</span></el-col
      >
      <el-col :span="4"
        >QQ'd<br /><span>{{ classResults.doubleQualified }}</span></el-col
      >
      <el-col :span="4"
        ># in Class<br /><span>{{
          classResults.totalDogsInClass
        }}</span></el-col
      >
      <el-col :span="4"
        ># Qualified<br /><span>{{
          classResults.totalDogsQualified
        }}</span></el-col
      >
      <el-col :span="4"></el-col>
      <!-- <el-col :span="4"></el-col> -->
    </el-row>
    <el-row :gutter="10" type="flex" justify="center">
      <el-col :span="4"
        >Title Points<br /><span>{{ classResults.titlePoints }}</span></el-col
      >
      <el-col :span="4"
        >Leg #<br /><span>{{ classResults.titleLeg }}</span></el-col
      >
      <el-col :span="4"
        >Title Toward<br /><span>{{ classResults.titleTowards }}</span></el-col
      >
      <el-col :span="4"
        >Title Achieved<br /><span>{{
          classResults.titleAchieved
        }}</span></el-col
      >
      <el-col :span="4"
        >Setting<br /><span>{{ outsideIndoors }}</span></el-col
      >
      <el-col :span="4"
        >Surface<br /><span>{{ runningSurface }}</span></el-col
      >
      <!-- <el-col :span="4"></el-col> -->
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    classResults: Object,
    outsideIndoors: String,
    runningSurface: String,
  },
  computed: {
    calcYPS() {
      const yps = (this.classResults.yards / this.classResults.dogTime).toFixed(
        2
      );
      return yps !== "NaN" ? yps : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 30px;
}

.el-col {
  text-align: center;
}

.dog-scores {
  color: $primary;
}
.dog-scores span {
  color: $textPrimary;
}

.secondary-text-color {
  color: $secondary;
}
</style>
