export class ScoreboardRow {
  constructor(opts = undefined) {
    if (opts !== null) {
      this.dogScore = opts.dogScore ? opts.dogScore : "";
      this.dogTime = opts.dogTime ? opts.dogTime : "";
      this.dogName = opts.dogName ? opts.dogName : "";
      this.dogBreed = opts.dogBreed ? opts.dogBreed : "";
    }
  }
}
