<template>
  <section>
    <div class="trial-header-left">
      <h3 class="primary">{{ trial.sanctionBody }} Event</h3>
      <h3 class="primary normalWeight">{{ trial.eventType }}</h3>
    </div>
    <div class="trial-header-middle">
      <h3>{{ trial.trialName }}</h3>
      <h3 class="normalWeight">
        <font-awesome-icon :icon="['far', 'calendar-alt']"></font-awesome-icon>
        {{ formatTrialDates }}
      </h3>
    </div>
    <div class="trial-header-left">
      <font-awesome-icon :icon="['fa', 'map-marker-alt']"></font-awesome-icon>
      <h3 class="normalWeight">{{ trial.location }}</h3>
      <h3 class="normalWeight">{{ trial.city }}, {{ trial.state }}</h3>
    </div>
  </section>
</template>

<script>
export default {
  props: { trial: Object },
  computed: {
    formatTrialDates() {
      return `${this.trial.formatMonthAndDay(
        this.trial.startDate
      )} - ${this.trial.formatMonthAndDay(this.trial.endDate)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;

  h3 {
    margin: 10px 0 0;
  }

  svg {
    color: $tertiary;
  }
}

.trial-header-right {
  width: 20%;
}

.trial-header-middle {
  width: 20%;
  padding-right: 40px;
}

.trial-header-left {
  position: relative;
  padding-left: 20px;
  padding-right: 40px;
  width: 20%;

  svg {
    position: absolute;
    top: 10px;
    left: 0;
  }
}
</style>
