<template>
  <base-spinner v-if="!classResults"></base-spinner>
  <div v-else-if="classResults">
    <div class="dog-top-actions">
      <span v-if="classResults._id">
        <el-tooltip effect="dark" content="Delete Results" placement="left">
          <font-awesome-icon
            @Click="handleDeleteResults"
            :icon="['fa', 'minus-circle']"
          ></font-awesome-icon>
        </el-tooltip>
        <el-tooltip effect="dark" content="Edit Results" placement="left">
          <font-awesome-icon
            @Click="handleUpdateResults"
            :icon="['fa', 'edit']"
          ></font-awesome-icon>
        </el-tooltip>
      </span>
    </div>
    <section>
      <TrialHeader :trial="trial" />
    </section>
    <section>
      <TrialClassHeader :dogPlacement="classResults.placement" />
    </section>
    <div v-if="classResults._id !== undefined && !editResults">
      <section>
        <DogScoreCard
          :classResults="classResults"
          :outsideIndoors="outsideIndoors"
          :runningSurface="runningSurface"
        />
      </section>
      <section class="scoreboard">
        <div class="scoreboard-row header-row">
          <p class="place"></p>
          <p class="dog-score">Score</p>
          <p class="dog-time">Time</p>
          <p class="dog-YPS">YPS</p>
          <p class="dog-name">Name</p>
          <p class="dog-breed">Breed</p>
        </div>
        <ScoreboardRow
          v-for="(singleScore, index) in classResults.runScoreboard"
          :key="index"
          :place="index + 1"
          :score="singleScore"
          :isDogPlacement="isDogPlacement(index + 1)"
          :courseLength="classResults.yards"
        />
      </section>
      <section>
        <p>Comments:</p>
        <p>{{ classResults.runComment }}</p>
      </section>
    </div>
    <div v-else>
      <h2 v-if="!classResults._id">
        No results for this class, would you like to enter them now?
      </h2>
      <AddResults
        :updateClassResults="updateClassResults"
        :classResults="classResults"
        :sanctionBody="trial.sanctionBody"
        :closeEditForm="closeEditForm"
        :createOrEdit="getCreateOrEditStatus"
      />
    </div>
  </div>
</template>

<script>
import { ClassResults } from "@/models/classResultsModel";
import {
  getDogClassResults,
  updateResults,
  removeResults,
} from "../../routes/dog";

import TrialHeader from "@/components/trial/TrialHeader.vue";
import TrialClassHeader from "@/components/trial/TrialClassHeader.vue";
import DogScoreCard from "./DogScoreCard.vue";
import ScoreboardRow from "./ScoreboardRow.vue";
import AddResults from "./AddResults.vue";

export default {
  props: {
    trial: Object,
    resultsId: String,
    outsideIndoors: String,
    runningSurface: String,
  },
  components: {
    TrialHeader,
    TrialClassHeader,
    DogScoreCard,
    ScoreboardRow,
    AddResults,
  },
  data() {
    return {
      token: this.$store.getters["user/getUserTokens"].idToken,
      classResults: undefined,
      editResults: false,
    };
  },
  computed: {
    getToken() {
      return this.$store.getters["user/getUserTokens"].idToken;
    },
    dogJumpHeight() {
      return this.$store.getters["dog/getDog"].jumpHeight;
    },
    getCreateOrEditStatus() {
      return this.editResults ? "edit" : "create";
    },
  },
  methods: {
    isDogPlacement(place) {
      return this.classResults.placement === place;
    },
    updateClassResults(updatedResults) {
      this.classResults = updatedResults;
    },
    handleUpdateResults() {
      this.editResults = true;
    },
    async handleDeleteResults() {
      this.$confirm(
        `Are you sure you want delete all stats for this class?`,
        "Warning",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            const resultsId = this.classResults._id;
            const classId = this.classResults.classId;
            const dogId = this.classResults.dogId;
            const token = this.getToken;

            // Remove results ObjectId from trialClass registration for dog
            await removeResults(token, dogId, classId);

            // Update classResults with { isDeleted: true, isDeletedDate: new Date() }
            await updateResults(token, resultsId, {
              isDeleted: true,
              isDeletedDate: new Date(),
            });

            this.classResults = new ClassResults({});
            this.editResults = true;

            this.$message({
              showClose: true,
              message: "Results Deleted",
              type: "success",
              duration: 5000,
            });
          } catch (error) {
            console.log(error);
            this.$message({
              showClose: true,
              message: "Error DRD1000 deleting results",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch(() =>
          this.$message({
            type: "info",
            message: "Delete Cancelled",
          })
        );
    },
    closeEditForm() {
      this.editResults = false;
    },
  },
  async created() {
    if (this.token && this.resultsId) {
      const resultsFromDb = await getDogClassResults(
        this.token,
        this.resultsId
      );
      this.classResults = new ClassResults(resultsFromDb.data);
    } else {
      this.classResults = new ClassResults({});
    }
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 30px;
}

.el-col {
  text-align: center;
}

// Global scoreboard styles used for this component
.scoreboard {
  padding: 20px 0;
}

.header-row p {
  margin-bottom: 0;
  color: $textSecondary;
  font-size: 0.9rem;
  border: 1px solid $siteBackground;
}

.header-row .place {
  background: none;
}
</style>
