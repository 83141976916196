<template>
  <div v-if="isLoading"><base-spinner></base-spinner></div>
  <div class="results-form" v-else-if="!isLoading">
    <el-form
      ref="addResultsForm"
      :model="addResultsForm"
      :rules="formRules"
      label-position="top"
    >
      <section>
        <el-form-item prop="didNotFinish">
          <el-switch
            class="switchSecondary"
            v-model="addResultsForm.didNotFinish"
            active-text="Dog did not finish (DNF)"
          ></el-switch>
        </el-form-item>
        <el-form-item prop="fixAndContinue">
          <el-switch
            class="switchSecondary"
            v-model="addResultsForm.fixAndContinue"
            active-text="Chose to fix and continue"
          ></el-switch>
        </el-form-item>
      </section>

      <section>
        <el-form-item label="Dog Score" prop="dogScore">
          <el-input-number
            v-model="addResultsForm.dogScore"
            :controls="false"
            @change="addDogToScoreboard"
            type="number"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="Dog Time" prop="dogTime">
          <el-input-number
            v-model="addResultsForm.dogTime"
            :controls="false"
            :precision="2"
            @change="addDogToScoreboard"
            type="number"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="Dog Jump Height" prop="dogJumpHeight">
          <el-input
            v-model="addResultsForm.dogJumpHeight"
            :controls="false"
            style="width: 150px"
            ><template #append>inches</template></el-input
          >
        </el-form-item>

        <el-form-item label="Course Time" prop="courseTime">
          <el-input-number
            v-model="addResultsForm.courseTime"
            :controls="false"
            :precision="2"
            type="number"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="Course Yards" prop="yards">
          <el-input-number
            v-model="addResultsForm.yards"
            :controls="false"
            type="number"
          ></el-input-number>
        </el-form-item>
      </section>

      <section>
        <el-form-item label="Dog Placement" prop="placement">
          <el-input-number
            v-model="addResultsForm.placement"
            :controls="false"
            type="number"
            @change="addDogToScoreboard"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="No. Dogs in Class" prop="totalDogsInClass">
          <el-input-number
            v-model="addResultsForm.totalDogsInClass"
            :controls="false"
            type="number"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="No. Qualified" prop="totalDogsQualified">
          <el-input-number
            v-model="addResultsForm.totalDogsQualified"
            :controls="false"
            type="number"
          ></el-input-number>
        </el-form-item>

        <div class="form-checkbox">
          <el-form-item label="Dog Q'd" prop="qualified">
            <el-checkbox v-model="addResultsForm.qualified"></el-checkbox>
          </el-form-item>
        </div>

        <div class="form-checkbox">
          <el-form-item label="Dog QQ'd" prop="doubleQualified">
            <el-checkbox v-model="addResultsForm.doubleQualified"></el-checkbox>
          </el-form-item>
        </div>
      </section>

      <section>
        <el-form-item label="Title Points" prop="titlePoints">
          <el-input-number
            v-model="addResultsForm.titlePoints"
            :controls="false"
            type="number"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="Leg #" prop="titleLeg">
          <el-input-number
            v-model="addResultsForm.titleLeg"
            :controls="false"
            type="number"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="Toward Title" prop="titleTowards">
          <el-autocomplete
            v-model="addResultsForm.titleTowards"
            :clearable="true"
            :fetch-suggestions="getDogTitles"
            :trigger-on-focus="false"
          ></el-autocomplete>
        </el-form-item>

        <div class="form-checkbox">
          <el-form-item label="Title Achieved" prop="titleAchieved">
            <el-checkbox v-model="addResultsForm.titleAchieved"></el-checkbox>
          </el-form-item>
        </div>
      </section>

      <div class="dynamic-inputs">
        <h3 class="normalWeight">Class Scoreboard</h3>
        <el-form-item
          v-for="(scoreCard, index) in addResultsForm.runScoreboard"
          :key="scoreCard.itemId"
          :prop="`runScoreboard.${index}.value`"
        >
          <section>
            <h3 class="normalWeight scoreboard-place">
              {{ index + 1 }}{{ getNumberSuffix(index + 1) }}
            </h3>
            <el-form-item label="Score">
              <el-input-number
                v-model="scoreCard.dogScore"
                :controls="false"
                type="number"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="Time">
              <el-input-number
                v-model="scoreCard.dogTime"
                :controls="false"
                type="number"
              ></el-input-number
            ></el-form-item>
            <el-form-item label="Dog Name">
              <el-input
                class="full-width-input"
                v-model="scoreCard.dogName"
              ></el-input
            ></el-form-item>
            <el-form-item label="Dog Breed">
              <el-input
                class="full-width-input"
                v-model="scoreCard.dogBreed"
              ></el-input
            ></el-form-item>
            <font-awesome-icon
              :icon="['fa', 'minus-circle']"
              @click.prevent="removeItem(scoreCard, 'runScoreboard')"
            ></font-awesome-icon>
          </section>
        </el-form-item>
        <div class="add-button">
          <base-button color="primary" @click.prevent="addItem('runScoreboard')"
            >Add Dog To Scoreboard</base-button
          >
        </div>
      </div>

      <el-form-item label="Comments" prop="runComment">
        <el-input
          v-model="addResultsForm.runComment"
          :clearable="true"
          type="textarea"
          :rows="3"
        ></el-input>
      </el-form-item>

      <div class="buttons">
        <base-button color="tertiary" @click.prevent="handleClearForm"
          >Clear</base-button
        >
        <base-button color="secondary" @click.prevent="submitForm"
          >Save</base-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import { ClassResults } from "@/models/classResultsModel";
import { uuid } from "vue-uuid";
import { getNumberSuffix } from "../../utils/numberOrdinalSuffix";

import { createResults, updateResults } from "@/routes/dog.js";

import moment from "moment";

export default {
  props: {
    updateClassResults: Function,
    classResults: Object,
    sanctionBody: String,
    createOrEdit: { type: String, required: true },
    closeEditForm: { type: Function, required: false },
  },
  data() {
    return {
      isLoading: false,
      addResultsForm: {
        didNotFinish: this.classResults.didNotFinish || false,
        fixAndContinue: this.classResults.fixAndContinue || false,
        dogScore: this.classResults.dogScore || undefined,
        dogTime: this.classResults.dogTime || undefined,
        dogJumpHeight:
          this.classResults.dogJumpHeight || this.getDogJumpHeight(),
        courseTime: this.classResults.courseTime || undefined,
        yards: this.classResults.yards || undefined,
        qualified: this.classResults.qualified || false,
        doubleQualified: this.classResults.doubleQualified || false,
        placement: this.classResults.placement || undefined,
        totalDogsInClass: this.classResults.totalDogsInClass || undefined,
        totalDogsQualified: this.classResults.totalDogsQualified || undefined,
        titlePoints: this.classResults.titlePoints || undefined,
        titleLeg: this.classResults.titleLeg || undefined,
        titleTowards: this.classResults.titleTowards || "",
        titleAchieved: this.classResults.titleAchieved || false,
        runScoreboard: this.classResults.runScoreboard || [
          {
            itemId: uuid.v4(),
            dogScore: undefined,
            dogTime: undefined,
            dogName: "",
            dogBreed: "",
          },
          {
            itemId: uuid.v4(),
            dogScore: undefined,
            dogTime: undefined,
            dogName: "",
            dogBreed: "",
          },
          {
            itemId: uuid.v4(),
            dogScore: undefined,
            dogTime: undefined,
            dogName: "",
            dogBreed: "",
          },
          {
            itemId: uuid.v4(),
            dogScore: undefined,
            dogTime: undefined,
            dogName: "",
            dogBreed: "",
          },
        ],
        runComment: this.classResults.runComment || "",
      },
      getNumberSuffix: getNumberSuffix,
      addItems: {
        runScoreboard: {
          dogScore: undefined,
          dogTime: undefined,
          dogName: "",
          dogBreed: "",
        },
      },
      defaultRunScoreboard: [
        {
          itemId: uuid.v4(),
          dogScore: undefined,
          dogTime: undefined,
          dogName: "",
          dogBreed: "",
        },
        {
          itemId: uuid.v4(),
          dogScore: undefined,
          dogTime: undefined,
          dogName: "",
          dogBreed: "",
        },
        {
          itemId: uuid.v4(),
          dogScore: undefined,
          dogTime: undefined,
          dogName: "",
          dogBreed: "",
        },
      ],
      formRules: {
        dogScore: [
          {
            required: false,
            message: "Please enter dog score (ex. 97)",
            trigger: "blur",
          },
        ],
        dogTime: [
          {
            required: false,
            message: "Please enter dog time (ex. 56.23)",
            trigger: "blur",
          },
        ],
        placement: [
          {
            required: false,
            message: "Please enter where your dog placed",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    getToken() {
      return this.$store.getters["user/getUserTokens"].idToken;
    },
    getUserId() {
      return this.$store.getters["user/getUserId"];
    },
    getPlaceSuffix() {
      return this.getNumberSuffix(1);
    },
  },
  methods: {
    getDogId() {
      return this.$store.getters["dog/getDog"]._id;
    },
    getClassId() {
      return this.$store.getters["dog/getDogActiveClass"]._id;
    },
    getDogName() {
      return this.$store.getters["dog/getDog"].callName;
    },
    getDogBreed() {
      return this.$store.getters["dog/getDog"].breed;
    },
    getDogTitles(queryString, cb) {
      let allTitles = this.$store.getters["dog/getDog"].titles;
      let titles = [];

      //add titles to titles array only if they are not achieved
      allTitles.forEach(
        (title) =>
          !title.dateEarned &&
          titles.push({ itemdId: title.itemId, value: title.title })
      );

      let results = queryString
        ? titles.filter(this.createFilter(queryString))
        : titles;
      // call callback function to return suggestions
      cb(results);
      // return this.$store.getters["dog/getDog"].titles;
    },
    createFilter(queryString) {
      return (title) =>
        title.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
    },
    getDogJumpHeight() {
      const defaultJumpHeight = this.$store.getters["dog/getDog"].jumpHeight;

      const venueJumpHeights =
        this.$store.getters["dog/getDog"].venueJumpHeights;

      return venueJumpHeights && venueJumpHeights.length > 0
        ? this.getVenueJumpHeight(defaultJumpHeight, venueJumpHeights)
        : defaultJumpHeight;
    },
    getVenueJumpHeight(defaultJumpHeight, venueJumpHeights) {
      const venueJumpHeight = venueJumpHeights.find(
        (jumpHeight) => jumpHeight.venue === this.sanctionBody
      );

      return venueJumpHeight ? venueJumpHeight.jumpHeight : defaultJumpHeight;
    },
    handleClearForm() {
      this.$refs["addResultsForm"].resetFields();
      this.addResultsForm.runScoreboard = this.defaultRunScoreboard;
    },
    addItem(itemArray) {
      const itemId = uuid.v4();
      this.addResultsForm[itemArray].push({
        itemId,
        ...this.addItems[itemArray],
      });
    },
    removeItem(item, itemArray) {
      const newItems = this.addResultsForm[itemArray].filter((currentItem) => {
        return currentItem !== item;
      });
      this.addResultsForm[itemArray] = newItems;
    },
    addDogToScoreboard() {
      if (
        this.addResultsForm.placement >= 1 &&
        this.addResultsForm.placement <= 4
      ) {
        const placeIndex = this.addResultsForm.placement - 1;
        const updatedRunScoreboard = [...this.addResultsForm.runScoreboard];
        updatedRunScoreboard[placeIndex] = {
          itemId: uuid.v4(),
          dogScore: this.addResultsForm.dogScore,
          dogTime: this.addResultsForm.dogTime,
          dogName: this.getDogName(),
          dogBreed: this.getDogBreed(),
        };
        this.addResultsForm.runScoreboard = updatedRunScoreboard;
      }
    },
    async submitForm() {
      this.isLoading = true;
      let notificationMessage = "Results Added";

      this.$refs["addResultsForm"].validate(async (valid) => {
        if (valid) {
          try {
            const dogId = this.getDogId();
            const classId = this.getClassId();

            const resultsForm = new ClassResults({
              ...this.addResultsForm,
              classId,
              dogId,
            });

            // need to check if this is creating a new result
            // or updating an existing result
            // and use correct route for each so as not to
            // create multiple results for the same dog/class

            if (this.createOrEdit === "create") {
              const response = await createResults(
                this.getToken,
                this.getUserId,
                resultsForm
              );
              this.updateClassResults(new ClassResults(response.data));
              notificationMessage = "Results Created";
            } else if (this.createOrEdit === "edit") {
              const response = await updateResults(
                this.getToken,
                this.classResults._id,
                resultsForm
              );

              this.updateClassResults(new ClassResults(response.data.value));
              notificationMessage = "Results Updated";
              this.closeEditForm();
            }

            if (resultsForm.titleTowards) {
              const dogId = this.$store.getters["dog/getDog"]._id;
              const activeClass = this.$store.getters["dog/getDogActiveClass"];
              const dogTitles = this.$store.getters["dog/getDog"].titles;
              const dogActiveTitles =
                this.$store.getters["dog/getDogActiveTitles"];

              const titleAlreadyExists = dogTitles.some(
                (title) => title.title === resultsForm.titleTowards
              );

              // check to see if title already exists
              // don't want to add the same title multiple times
              if (!titleAlreadyExists) {
                const dogTitle = {
                  itemId: uuid.v4(),
                  title: resultsForm.titleTowards,
                  dateEarned: resultsForm.titleAchieved
                    ? moment(activeClass.classDate).format("MM/DD/YYYY")
                    : "",
                  venue: this.sanctionBody,
                };

                dogTitles.push(dogTitle);
                try {
                  await this.$store.dispatch("dog/updateDog", {
                    dogId: this.$store.getters["dog/getDog"]._id,
                    update: { titles: dogTitles },
                  });
                  await this.$store.dispatch("dog/setDogActiveTitles", {
                    titles: dogTitles,
                    dogId: dogId,
                  });
                } catch (error) {
                  console.log("Error adding title to dog");
                  this.$message({
                    showClose: true,
                    message: "Error adding title to dog",
                    type: "error",
                    duration: 5000,
                  });
                }
              } else {
                // add points to title in dogActiveTitles
                const updatedDogActiveTitles = dogActiveTitles.map(
                  (activeTitle) => {
                    if (activeTitle.title === resultsForm.titleTowards) {
                      activeTitle = {
                        ...activeTitle,
                        dateEarned: resultsForm.titleAchieved
                          ? moment(activeClass.classDate).format("MM/DD/YYYY")
                          : "",
                        titlePoints:
                          activeTitle.titlePoints + resultsForm.titlePoints,
                      };
                      return activeTitle;
                    } else {
                      return activeTitle;
                    }
                  }
                );

                // update dog titles in DB if title achived
                if (resultsForm.titleAchieved && resultsForm.titleTowards) {
                  const updatedDogTitles = dogTitles.map((title) => {
                    if (title.title === resultsForm.titleTowards) {
                      title = {
                        ...title,
                        dateEarned: moment(activeClass.classDate).format(
                          "MM/DD/YYYY"
                        ),
                      };

                      return title;
                    } else {
                      return title;
                    }
                  });

                  await this.$store.dispatch("dog/updateDog", {
                    dogId: dogId,
                    update: { titles: updatedDogTitles },
                  });
                }

                this.$store.dispatch("dog/setDogActiveTitles", {
                  titles: updatedDogActiveTitles,
                  dogId: this.$store.getters["dog/getDog"]._id,
                });
              }
            }

            this.$message({
              showClose: true,
              message: notificationMessage,
              type: "success",
              duration: 5000,
            });
          } catch (error) {
            console.log(error);
            this.$message({
              showClose: true,
              message: "Error ARF1000 saving results",
              type: "error",
              duration: 5000,
            });
          }
        } else {
          console.log("error submitting!!");
        }
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.results-form {
  max-width: 1000px;
  margin: 0 auto;
}

section {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .el-checkbox__input {
    margin-left: 20px;
  }
}

.form-checkbox .el-checkbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;
}

.el-input-number,
.el-checkbox {
  width: 150px;
  margin-right: 15px;
}

.el-input {
  width: 250px;
  margin-right: 15px;
}

.dynamic-inputs {
  padding: 50px 0;
  margin: 10px 0 20px;
  border-top: 1px solid $borderLight;
  border-bottom: 1px solid $borderLight;

  h3 {
    margin-top: 0;
    color: $textSecondary;
  }

  .scoreboard-place {
    width: 50px;
    margin-top: 35px;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .full-width-input {
    width: 95%;
  }

  .classification-input {
    width: 55%;
  }

  .info-input {
    width: 35%;
  }

  svg {
    width: 3%;
    margin: 50px 0 0 10px;
    color: $tertiary-75;

    :hover {
      cursor: pointer;
      color: $secondary;
    }
  }

  button {
    margin-right: 10px;
    text-align: right;
  }

  .add-button {
    display: flex;
    justify-content: flex-end;
  }
}

.buttons {
  text-align: right;

  button {
    margin-right: 10px;
  }
}

// global switch styles controlled by element-variables.scss
.el-switch {
  margin-bottom: 15px;
  margin-right: 60px;
}
</style>
