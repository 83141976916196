<template>
  <div class="trial-class-header">
    <section class="trial-class-section">
      <h3 class="secondary trial-class-label">{{ trialClass.trialClass }}</h3>
      <h3 class="normalWeight trial-level-label">{{ trialClass.level }}</h3>
      <p>
        <font-awesome-icon :icon="['fa', 'user']"></font-awesome-icon
        >{{ getHandler }}
      </p>
      <p class="trial-judge">
        <font-awesome-icon :icon="['fa', 'address-card']"></font-awesome-icon
        >{{ trialClass.judge }}
      </p>
    </section>
    <section
      class="trial-placement-section"
      :class="{ 'dog-placed': dogPlaced }"
      v-if="dogPlacement !== 0"
    >
      <p>
        <font-awesome-icon :icon="['fa', getPlacementIcon]"></font-awesome-icon>
      </p>
      <h3>{{ dogPlacement }}{{ getSuffix }}</h3>
      <p class="placed">place</p>
    </section>
  </div>
</template>

<script>
import { getNumberSuffix } from "../../utils/numberOrdinalSuffix";

export default {
  props: { dogPlacement: Number, dogId: String },
  data() {
    return {
      trialClass: undefined,
      getNumberSuffix: getNumberSuffix,
    };
  },
  computed: {
    getDogId() {
      return this.$store.getters["dog/getDog"]._id;
    },
    getHandler() {
      const dogRegistration = this.trialClass.registrations.find(
        (registration) => registration.dogId === this.getDogId
      );

      return dogRegistration.handler;
    },
    getPlacementIcon() {
      return this.dogPlacement <= 3 ? "medal" : "award";
    },
    dogPlaced() {
      return this.dogPlacement <= 3 ? true : false;
    },
    getSuffix() {
      return this.getNumberSuffix(this.dogPlacement);
    },
  },
  methods: {
    getTrialClass() {
      this.trialClass = this.$store.getters["dog/getDogActiveClass"];
    },
  },
  created() {
    this.getTrialClass();
  },
};
</script>

<style lang="scss" scoped>
h3 {
  padding: 0;
  margin: 0;
  color: $tertiary;
}

p {
  margin: 0;
  padding: 0;
}

section {
  padding: 10px;
}

.trial-class-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 0 20px 10px;
  margin-bottom: 30px;
  border-bottom: 1px solid $borderLight;
}

.trial-class-section {
  margin-right: 30px;
  width: 33%;

  .trial-level-label {
    margin: 4px 0 15px;
  }

  .trial-judge {
    margin-top: 4px;
  }

  svg {
    margin-right: 5px;
    color: $tertiary-85;
  }
}

.trial-placement-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;

  h3 {
    padding-top: 4px;
    font-size: 1.25rem;
  }

  p.placed {
    opacity: 0.75;
  }

  svg {
    height: 30px;
    width: 30px;
    color: $tertiary;
  }

  &.dog-placed {
    color: $secondary;

    h3 {
      color: $secondary;
    }

    svg {
      color: $secondary;
    }
  }
}
</style>
