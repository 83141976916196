export class ClassRegistration {
  constructor(opts = undefined) {
    if (opts !== null) {
      this.dogId = opts.dogId && opts.dogId;
      this.dateCancelled = opts.dateCancelled && opts.dateCancelled;
      this.dateDeleted = opts.dateDeleted && opts.dateDeleted;
      this.dateEntered = opts.dateEntered && opts.dateEntered;
      this.dateUpdated = opts.dateUpdated && opts.dateUpdated;
      this.dateWithdrawl = opts.dateWithdrawl && opts.dateWithdrawl;
      this.handler = opts.handler && opts.handler;
      this.isCancelled = opts.isCancelled && opts.isCancelled;
      this.isDeleted = opts.isDeleted && opts.isDeleted;
      this.isWithdral = opts.isWithdral && opts.isWithdral;
      this.jumpHeight = opts.jumpHeight && opts.jumpHeight;
      this.status = opts.status && opts.status;
      this.results = opts.results ? opts.results : null;
    }
  }
}
