<template>
  <base-spinner v-if="loadingTrial"></base-spinner>
  <div v-else-if="!loadingTrial">
    <DogResultsDetail
      :trial="getTrial"
      :resultsId="hasResultsId"
      :outsideIndoors="getTrial.outsideIndoors"
      :runningSurface="getTrial.runningSurface"
    />
  </div>
  <h2 v-else>No data found, something went wrong</h2>
</template>

<script>
import DogResultsDetail from "@/components/dog/DogResultsDetail.vue";

import { ClassRegistration } from "../models/classRegistrationModel";

export default {
  props: { dogId: String, classId: String, trialId: String },
  components: { DogResultsDetail },

  data() {
    return {
      loadingTrial: false,
      loadingClass: false,
    };
  },
  computed: {
    hasResultsId() {
      const hasRegistration = this.$store.getters[
        "dog/getDogActiveClass"
      ].registrations.find((registration) => registration.dogId === this.dogId);
      const registration = hasRegistration
        ? new ClassRegistration(hasRegistration)
        : new ClassRegistration();
      return registration.results;
    },
    getTrial() {
      const hasActiveTrial = this.$store.getters["dog/getDogActiveTrial"];
      return hasActiveTrial ? hasActiveTrial : this.getTrialFromDB();
    },
  },
  methods: {
    async getTrialFromDB() {
      this.loadingTrial = true;

      try {
        await this.$store.dispatch("dog/loadDogActiveTrial", this.trialId);
      } catch (error) {
        console.log(error);
        this.$message({
          showClose: true,
          message: "Error DCRV1000 getting active trial",
          type: "error",
          duration: 5000,
        });
      }

      this.loadingTrial = false;
    },
  },
  created() {
    const hasActiveTrial = this.$store.getters["dog/getDogActiveTrial"];
    !hasActiveTrial && this.getTrialFromDB();
  },
};
</script>
