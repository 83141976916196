export function getNumberSuffix(numberToSuffix) {
  if (numberToSuffix > 3 && numberToSuffix < 20) {
    return "th";
  }

  const checkNumber = numberToSuffix.toString();

  switch (checkNumber.charAt(checkNumber.length - 1)) {
    case "1":
      return "st";
    case "2":
      return "nd";
    case "3":
      return "rd";
    default:
      return "th";
  }
}
